import { render, staticRenderFns } from "./main-header.vue?vue&type=template&id=08c191e6"
import script from "./main-header.vue?vue&type=script&lang=js"
export * from "./main-header.vue?vue&type=script&lang=js"
import style0 from "./_main-header.scss?vue&type=style&index=0&prod&lang=scss&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {WebLogo: require('/usr/src/app/components/ui/WebLogo/WebLogo.vue').default,BaseButton: require('/usr/src/app/components/ui/BaseButton/BaseButton.vue').default})
