import { REGIONS } from '@/modules/region/domain/region';

import { createUpsell } from '../upsell';

export const jdz8pH = createUpsell({
  id: 'jdz8pH',
  desc: 'Upsell sequence with 12-month offer',
  regions: [REGIONS.au, REGIONS.br, REGIONS.ca, REGIONS.join, REGIONS.latam, REGIONS.uk, REGIONS.sa],
  seq: ['upsell-12-month', 'upsell-fast-food', 'upsell-detox', 'upsell-alcohol', 'upsell-sweet-treats'],
});
